export const featureFlags = {
  INSTANT_REFUNDS: 'instant-refunds',
  RECHARGE_ENABLED: 'recharge-refunds',
  SMART_RECOMMENDATIONS: 'smart-recommendations',
  STATIC_WORKFLOW_TEMPLATES: 'static-workflow-templates',
  STATIC_WORKFLOW_TEMPLATES_RETURNLY_TAGS: 'static-workflow-templates-returnly-tags',
  PACKING_SLIP_TRANSLATION: 'packing-slip-translation',
  SHOP_SCOPED_ROLES_ENABLED: 'shop-scoped-roles-enabled',
  REJECT_ITEM_EDIT_RETURN_ENABLED: 'reject-item-edit-return-enabled',
  WORKFLOWS_TESTBENCH: 'workflows-testbench',
  PAYMENTS_STRIPE_3DS2: 'payments-stripe-3ds2',
  AUTH0_SESSIONS_ENABLED: 'auth0-sessions-enabled',
  REJECT_RETURN_V2: 'reject-return-v2',
  RETURN_REFUND_SERVICE_ENABLED: 'return-refund-service-enabled',
  OCEANIA_ADDRESS_IMPROVEMENTS_ENABLED: 'oceania-address-improvements-enabled',
  PARTIAL_IX_REFUNDS: 'partial-ix-refunds',
  EMBEDDED_PORTAL: 'embedded-portal',
  SHIPPING_PROTECTION: 'shipping-protection',
  GOODDATA_ANALYTICS: 'good-data-analytics',
  OFFSET_HANDLING_FEE_EXCEPTIONS: 'offset-handling-fee-exceptions',
  SHOPIFY_GRAPHQL_ENABLED: 'shopify-graph-ql-enabled',
  LOOPY_AI: 'loopy-ai',
  NEW_ADMIN_WORKFLOWS: 'new-admin-workflows',
  GIFT_CARD_API_IMPROVEMENT: 'gift-card-api-improvement',
  ALLOW_LOGO_SIZE_ADJUSTMENTS: 'allow-logo-size-adjustments',
  NEW_SBL_NEGOTIATED_RATE_CARRIERS: 'new-sbl-negotiated-rate-carriers',
};
