import shipByLoop from '@/util/api/shipByLoop';
import sblConstants from '@/constants/shipByLoop';

export default {
  namespaced: true,
  state: {
    shippingSettingId: null,
    sbl: null,
    carrierShops: null,
    nextRoute: null,
    forwardBtnDisabled: true,
    error: null,
    isOwnAccount: false,
    carrier: null,
    stripe: null,
    stripeElements: null,
    stripeTokenId: '',
    cardInfo: null,
    businessAddress: null,
    recharge: null,
    credentials: null,
    businessInfo: null,
    invoiceInfo: null,
    paymentOnly: false,
    refreshPayment: false,
    removePayment: false,
    newEmail: null,
    isValidConfig: false,
    isConnecting: false,
    isProductionMode: true,
  },
  mutations: {
    setShippingSettingId(state, id) {
      state.shippingSettingId = id;
    },
    setSbl(state, sbl) {
      state.sbl = sbl;
    },
    setPartialSbl(state, partialSbl) {
      state.sbl = {
        ...state.sbl,
        ...partialSbl,
      };
    },
    setIsConnecting(state, isConnecting) {
      state.isConnecting = isConnecting;
    },
    setIsValidConfig(state, isValidConfig) {
      state.isValidConfig = isValidConfig;
    },
    setCarrierShops(state, carrierShops) {
      state.carrierShops = carrierShops;
    },
    setNextRoute(state, nextRoute) {
      state.nextRoute = nextRoute;
    },
    setForwardBtnDisabled(state, forwardBtnDisabled) {
      state.forwardBtnDisabled = forwardBtnDisabled;
    },
    setError(state, error) {
      state.error = error;
    },
    setIsOwnAccount(state, isOwnAccount) {
      state.isOwnAccount = isOwnAccount;
    },
    setCarrier(state, carrier) {
      state.carrier = carrier;
    },
    setStripe(state, stripe) {
      state.stripe = stripe;
    },
    setStripeElements(state, stripeElements) {
      state.stripeElements = stripeElements;
    },
    setStripeTokenId(state, stripeTokenId) {
      state.stripeTokenId = stripeTokenId;
    },
    setCardInfo(state, cardInfo) {
      state.cardInfo = cardInfo;
    },
    setBusinessAddress(state, businessAddress) {
      state.businessAddress = {
        ...state.businessAddress,
        ...businessAddress,
      };
    },
    setRecharge(state, recharge) {
      state.recharge = recharge;
    },
    setCredentials(state, credentials) {
      state.credentials = credentials;
    },
    setBusinessInfo(state, businessInfo) {
      state.businessInfo = businessInfo;
    },
    setInvoiceInfo(state, invoiceInfo) {
      state.invoiceInfo = invoiceInfo;
    },
    setPaymentOnly(state, paymentOnly) {
      state.paymentOnly = paymentOnly;
    },
    setRefreshPayment(state, refreshPayment) {
      state.refreshPayment = refreshPayment;
    },
    setRemovePayment(state, removePayment) {
      state.removePayment = removePayment;
    },
    setNewEmail(state, newEmail) {
      state.newEmail = newEmail;
    },
    setIsProductionMode(state, isProductionMode) {
      state.isProductionMode = isProductionMode;
    }
  },
  actions: {
    async connect({ state, commit, dispatch }) {
      commit('settings/shipByLoop/setIsConnecting', true, { root: true });
      try {
        const response = await shipByLoop.postConfig(state.sbl);
        commit('setSbl', response.data);
        commit('settings/shipByLoop/setIsNewIntegration', false, { root: true });
      } catch (error) {
        commit('setError', error);
      }

      commit('settings/shipByLoop/setIsConnecting', false, { root: true });
      await dispatch('getConfig');
    },
    async getConfig({ state, commit }) {
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      let sblId;

      try {
        sblId = state.sbl?.id ?? await shipByLoop.getSblId();
      } catch (error) {
        commit('setError', error.response);
        commit('settings/shipByLoop/setIsLoading', false, { root: true });
        return;
      }

      if (!sblId) {
        // SBL not connected yet, no config to fetch
        commit('settings/shipByLoop/setIsLoading', false, { root: true });
        return;
      }

      try {
        const { data: sblInfo } = await shipByLoop.getConfig(sblId);
        commit('setSbl', sblInfo);
        commit('setCardInfo', sblInfo.primaryPaymentMethod);
        commit('setRecharge', {
          threshold: sblInfo.rechargeThreshold,
          amount: sblInfo.rechargeAmount,
        });
        commit('setStripeTokenId', '');
        commit('settings/shipByLoop/setIsNewIntegration', false, { root: true });
        commit('setNewEmail', sblInfo.email);
        commit('setIsProductionMode', sblInfo.isProductionMode);
      } catch (error) {
        commit('setError', error);
      }

      try {
        const carrierShop = await shipByLoop.getCarrierShops();
        commit('setCarrierShops', carrierShop);
      } catch (error) {
        commit('setError', error);
      }

      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    async updateConfig({ state, commit }) {
      if (!state.sbl) {
        commit('setError', {
          message: 'Unable to retrieve service details. Please navigate back to the Shipping page and try again.',
        });
      }

      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      let payload = {
        address: state.businessAddress ?? state.sbl.address,
        companyName: state.businessAddress?.company,
        email: state.newEmail ?? state.sbl.email,
        isProductionMode: state.isProductionMode,
      };

      if (state.removePayment) {
        payload.deletePrimaryPaymentToken = true;
      } else {
        if (state.stripeTokenId) {
          payload.primaryPaymentToken = state.stripeTokenId;
        }
        if (state.cardInfo || state.stripeTokenId) {
          payload.rechargeThreshold = state.recharge?.threshold ?? state.sbl.rechargeThreshold;
          payload.rechargeAmount = state.recharge?.amount ?? state.sbl.rechargeAmount;
        }
      }

      try {
        const { data: res } = await shipByLoop.patchConfig(state.sbl.id, payload);

        commit('setSbl', res);
        commit('setCardInfo', res.primaryPaymentMethod);
        commit('setRecharge', {
          threshold: res.rechargeThreshold,
          amount: res.rechargeAmount,
        });
        commit('setStripeTokenId', '');
      } catch (error) {
        commit('setError', error.response);
      }

      commit('setRemovePayment', false);
      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    async deleteConfig({ state, commit }) {
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      try {
        const res = await shipByLoop.deleteConfig(state.sbl.id);
        commit('setSbl', null);
        commit('settings/shipByLoop/setIsNewIntegration', true, { root: true });
        commit('setIsProductionMode', true);
        commit('setNewEmail', null);
        commit('settings/shipByLoop/setIsLoading', false, { root: true });
        return res;
      } catch (error) {
        commit('setError', error);
        commit('settings/shipByLoop/setIsLoading', false, { root: true });
        throw error.response.data.message;
      }
    },
    async addCarrier({ state, commit }) {
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      let payload = {};
      if (state.carrier === sblConstants.USPS_SLUG) {
        payload.carrier = sblConstants.USPS_PLATFORM;
      } else if (state.carrier === sblConstants.FEDEX_SLUG) {
        payload.carrier = state.isOwnAccount ? sblConstants.FEDEX_OWN : sblConstants.FEDEX_PLATFORM;
      // } else if (state.carrier === sblConstants.UPS_SLUG) {
      //   payload.carrier = sblConstants.UPS_OWN;
      } else if (state.carrier === sblConstants.AUS_SLUG) {
        payload.carrier = sblConstants.AUS_SLUG;
      } else if (state.carrier === sblConstants.FEDEX_SMARTPOST_SLUG) {
        payload.carrier = sblConstants.FEDEX_SMARTPOST_SLUG;
      // } else if (state.carrier === sblConstants.UPS_MI_SLUG) {
      //   payload.carrier = sblConstants.UPS_MI_SLUG;
      } else if (state.carrier === sblConstants.CANADA_POST_PLATFORM) {
        payload.carrier = sblConstants.CANADA_POST_PLATFORM;
      } else if (state.carrier === sblConstants.DHL_ECOMMERCE_PLATFORM) {
        payload.carrier = sblConstants.DHL_ECOMMERCE_PLATFORM;
      } else if (state.carrier === sblConstants.DHL_EXPRESS_PLATFORM) {
        payload.carrier = sblConstants.DHL_EXPRESS_PLATFORM;
      } else {
        commit('setError', { message: 'Unable to resolve carrier.' });
        return;
      }

      if (state.isOwnAccount) {
        payload.companyName = state.businessAddress?.company;
        payload.email = state.newEmail ?? state.sbl.email;
        // Merchant account information
        if (state.credentials) {
          payload = { ...payload, ...state.credentials };
        }
        if (state.businessInfo) {
          payload.businessInfo = state.businessInfo;
        }
        if (state.invoiceInfo) {
          payload.invoiceInfo = state.invoiceInfo;
        }
        const address = state.businessAddress ?? state.sbl.address;
        if (address.street) {
          payload.address = address;
        }
      }

      try {
        const res = await shipByLoop.addCarrier(payload);
        commit('setCarrierShops', [ res ]);
        commit('setError', null);
      } catch (error) {
        commit('setError', error.response);
      }

      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    async deleteCarrier({ commit }, { shippingSettingId }) {
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      try {
        await shipByLoop.deleteCarrier(shippingSettingId);
      } catch (error) {
        console.error(error);
        commit('setError', error);
      }

      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    setUpStripe({ state, commit }) {
      commit('settings/shipByLoop/setIsLoading', true, { root: true });
      const stripe = Stripe(state.sbl?.stripePublicKey);
      const elements = stripe.elements();

      commit('setStripe', stripe);
      commit('setStripeElements', elements);
      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
    async getToken({ state, commit }, {
      cardholderName,
      streetAddress,
      aptNumber,
      city,
      stateName,
      zip,
      countryCode,
    }) {
      commit('settings/shipByLoop/setIsLoading', true, { root: true });

      const cardElement = state.stripeElements.getElement('card');

      const res = await state.stripe.createToken(cardElement, {
        name: cardholderName,
        address_line1: streetAddress ?? '',
        address_line2: aptNumber ?? '',
        address_city: city ?? '',
        address_state: stateName ?? '',
        address_zip: zip ?? '',
        address_country: countryCode ?? '',
      });

      if (!res.error) {
        commit('setStripeTokenId', res.token.id);
        commit('setCardInfo', {
          last4: res.token.card.last4,
          brand: res.token.card.brand,
          expirationMonth: res.token.card.exp_month,
          expirationYear: res.token.card.exp_year,
        });
        commit('setRemovePayment', false);
      } else {
        commit('settings/shipByLoop/setIsLoading', false, { root: true });
        throw res.error;
      }
      commit('settings/shipByLoop/setIsLoading', false, { root: true });
    },
  },
  getters: {
    sbl: state => state.sbl,
    carrierShops: state => state.carrierShops,
    nextRoute: state => state.nextRoute,
    forwardBtnDisabled: state => state.forwardBtnDisabled,
    loading: state => state.loading,
    error: state => state.error,
    isOwnAccount: state => state.isOwnAccount,
    carrier: state => state.carrier,
    stripe: state => state.stripe,
    stripeElements: state => state.stripeElements,
    stripeTokenId: state => state.stripeTokenId,
    cardInfo: state => state.cardInfo,
    businessAddress: state => state.businessAddress,
    recharge: state => state.recharge,
    credentials: state => state.credentials,
    businessInfo: state => state.businessInfo,
    invoiceInfo: state => state.invoiceInfo,
    paymentOnly: state => state.paymentOnly,
    isValidConfig: state => state.isValidConfig,
    isConnecting: state => state.isConnecting,
    refreshPayment: state => state.refreshPayment,
    removePayment: state => state.removePayment,
    newEmail: state => state.newEmail,
    isProductionMode: state => state.isProductionMode,
    hasChanges: state => {
      const { sbl, cardInfo, removePayment, recharge, newEmail, isProductionMode  } = state;

      if (!cardInfo && !newEmail) {
        return false;
      }

      if (removePayment) {
        return true;
      }

      if (
        sbl?.rechargeThreshold !== recharge?.threshold ||
        sbl?.rechargeAmount !== recharge?.amount
      ) {
        return true;
      }

      if (sbl?.email !== newEmail) {
        return true;
      }

      if (sbl?.isProductionMode !== isProductionMode) {
        return true;
      }

      return false;
    }
  }
};
