import { createApp } from 'vue';
import 'focus-visible';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/util/typedefs';
import directives from '@/util/directives';
import { capitalize, relativeTime, date, formatMoney, currency, currencyMajor, currencyAbbreviated, formatNumber } from '@/util/filters';
import { usePlugins } from '@/util/plugins';
import { registerGlobalComponents } from '@/util/components';
import '@/scss/main.scss';
import { initGA } from '@/util/helpers/ga';
import { initSegment } from '@/util/helpers/segment';
import env from '@/env';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { auth0 } from '@/util/auth0';
import '/node_modules/@loophq/ui/dist/style.css';
import '/node_modules/@loophq/design-system/dist/design-system.css';
import '/node_modules/@loophq/component-library/dist/style.css';
import setAxiosDefaults from '@/util/lib/axios';

const app = createApp(App);

// Plugins
usePlugins(app);

// Components
registerGlobalComponents(app);

//Directives
Object.keys(directives).forEach(key => {
  //@ts-ignore
  app.directive(key, directives[key]);
});

// Global methods
// Only for methods that are used VERY frequently
// Prefer methods in helper files
export const createId = (prefix: string = ''): string => {
  const random = [...Array(16)]
    .map(() => (~~(Math.random() * 36)).toString(36))
    .join('');
  return `${prefix}${random}`;
};
app.config.globalProperties.$createId = createId;

app.config.globalProperties.$filters = {
  capitalize,
  relativeTime,
  date,
  formatMoney,
  currency,
  currencyMajor,
  currencyAbbreviated,
  formatNumber
};

//Set up axios defaults
await setAxiosDefaults();


// Datadog RUM initialization
if (
  env('VITE_DATADOG_APPLICATION_ID_ADMIN_PORTAL')
  && env('VITE_DATADOG_CLIENT_TOKEN_ADMIN_PORTAL')
  && env('VITE_DATADOG_SAMPLE_RATE_ADMIN_PORTAL')
) {
  datadogRum.init({
    applicationId: env('VITE_DATADOG_APPLICATION_ID_ADMIN_PORTAL'),
    clientToken: env('VITE_DATADOG_CLIENT_TOKEN_ADMIN_PORTAL'),
    site: 'datadoghq.com',
    service: 'admin',
    env: env('VITE_DATADOG_ENV'),
    version: env('VITE_DATADOG_VERSION'),
    sessionSampleRate: env('VITE_DATADOG_SAMPLE_RATE_ADMIN_PORTAL'),
    trackUserInteractions: true,
    trackFrustrations: true,
    sessionReplaySampleRate: env('VITE_DATADOG_SESSION_REPLAY_RATE_ADMIN_PORTAL'),
    allowedTracingOrigins: ['*'],

  });
  datadogRum.startSessionReplayRecording();
}

if (env('VITE_DATADOG_CLIENT_TOKEN_ADMIN_PORTAL')) {
  datadogLogs.init({
    clientToken: env('VITE_DATADOG_CLIENT_TOKEN_ADMIN_PORTAL'),
    site: 'datadoghq.com',
    service: 'core-admin-portal',
    env: env('VITE_DATADOG_ENV'),
    version: env('VITE_DATADOG_VERSION'),
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 0,
  });
}
app
  .use(router)
  .use(store)
  .use(auth0)
  .mount('#app');

// Global stuff
initGA();
initSegment();
